 

export const GetAllData = async (apiUrl) => {
  return fetch(apiUrl).then(async (response) => {
    return response
      .json()
      .then((data) => {
        //console.log(data);
        return data;
      })
      .catch((err) => {
        //console.log(err);
      });
  }); 
};

export const GetAllDataByPostMethodBody = async (apiUrl, reqData) => { 
  return fetch(apiUrl,{
    method: "POST",
      headers: {
        // Don't set Content-Type, let the browser set it automatically for FormData
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
  }).then(async (response) => {
    return response
      .json()
      .then((data) => {
        //console.log(data);
        return data;
      })
      .catch((err) => {
        //console.log(err);
      });
  }); 
};


export const GetDropdownData = async (apiUrl) => {
    return fetch(apiUrl).then(async (response) => {
      return response
        .json()
        .then((data) => {
          //console.log(data);
          return data;
        })
        .catch((err) => {
          //console.log(err);
        });
    }); 
  };
  
