import React, { useState, useRef  } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'; 
import "../../assets/css/ModalStyle.css";

const AddModal = ({ title, isOpen, closeModal, onSave, maxRecordId, children }) => {
    const [isAllRequiredValied, setIsAllRequiredValied] = useState(null);
    
    const handelValidateField = (isValid) => {
        setIsAllRequiredValied(isValid);
    }

    const handleSave = () => {
        if (isAllRequiredValied) {           
            onSave(children.props.formData);
            closeModal();
        } else {
            alert('Please fill in all required fields.');   
        }
    };

    return (
        <>
            <Modal show={isOpen} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {React.cloneElement(children, {
                        addingData: children.props.addingData,
                        setAddingData: children.props.setAddingData,
                        maxRecordId: children.props.maxRecordId,
                        formData: children.props.formData,
                        setFormData: children.props.setFormData,
                        validateField: handelValidateField
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" onClick={handleSave} className='btn-sm small-font'>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={closeModal} className='btn-sm small-font'>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal> 
        </>
    )
}

export default AddModal