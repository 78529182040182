import {useState } from 'react'
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

const useUser = () => {
    const getUser = () => {
        const userInfo = LocalStorageHelper.getUser();
        const _user = JSON.parse(userInfo);
        return _user;
      };
      
    const [user, setUser] = useState(getUser());

    const saveUser = user => {
      LocalStorageHelper.setUser(user);
      setUser(user);
    };

    return {
      setUser: saveUser,
      user: user,
      token: user?.AccessToken
    }
}

export default useUser