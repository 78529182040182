// PagingComponent.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../assets/css/PagingComponent.css";

const PagingComponent = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  recordsFound,
  pageSizeOptions = [10, 50, 100],
}) => {
  const [pageSizes, setPageSizes] = useState(pageSizeOptions);
  const totalRecords = recordsFound || 0;
  const [goToPage, setGoToPage] = useState("");

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const handlePageSizeChange = (e) => {
    const newPageSize = parseInt(e.target.value, 10);
    if (!isNaN(newPageSize) && newPageSize > 0) {
      onPageSizeChange(newPageSize);
    }
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage, 10);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
      setGoToPage("");
    }
  };

  return (
    <div className="pagination-container">
      <div className="pagination-2">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`small-font ${currentPage === 1
            ? "btn-sm rounded-left disabled"
            : "btn-sm rounded-left"}`           
          }          
        >
          &laquo; Previous
        </button>

        <span className="margin-x small-font">
          Page <span className="big-font">{currentPage}</span> of {totalPages} (
          <span className="text-muted">total {totalRecords}</span>)
        </span>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`small-font ${currentPage === totalPages
            ? "btn-sm rounded-right disabled"
            : "btn-sm rounded-right"}`}          
        >
          Next &raquo;
        </button>

        <span className="ml-1 text-sm-left small-font">Size:</span>
        <select
          value={pageSize}
          onChange={handlePageSizeChange}
          className="margin-x select-option btn-sm small-font"           
          style={{marginTop:'4px' }}
        >
          {pageSizes &&
            pageSizes.map((psize, index) => {
              return (
                <option key={index} value={psize}>
                  {psize}
                </option>
              );
            })}
        </select>

        <span className="pagination-2 small-font">
          <div className="input-group">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={goToPage}
              onChange={(e) => setGoToPage(e.target.value)}
              className="input-box"
            />
            <div class="input-group-append">
              <button
                onClick={handleGoToPage}
                className="rounded-right text-sm-center"
              >
                Go
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

PagingComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
};

export default PagingComponent;
