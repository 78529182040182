import * as localforage from "localforage";
class LocalStorageHelper {
  // Set Get Site Info Footer
  static SetSiteInfoFooter(JSONData) {
    localforage.setItem("SiteInfoFooter", JSON.stringify(JSONData));
  }
  static GetSiteInfoFooter(callback) {
    localforage.getItem("SiteInfoFooter", function (err, value) {
      if (err) {
        callback(null);
      } else {
        callback(JSON.parse(value));
      }
    });
  }

  // Set/Get/Remove User
  static setUser(UserData) {
    localStorage.setItem("User", UserData);
  }
  static getUser() {
    return localStorage.getItem("User");
  }
  static removeUser() {
    return localStorage.removeItem("User");
  }

  // Set/Get/Remove User Mobile
  static setUserMobile(UserMobile) {
    localforage.setItem("UserMobile", UserMobile);
  }
  static getUserMobile() {
    return localforage.getItem("UserMobile");
  }
  static removeUserMobile() {
    return localforage.removeItem("UserMobile");
  }

  static SetRedirectFromDetails(winlocation) {
    sessionStorage.setItem("winlocation", winlocation);
  }

  static GetRedirectFromDetails() {
    return sessionStorage.getItem("winlocation");
  }

  // static isLoggedIn() {
  //   let token = JSON.parse(JSON.stringify(localStorage.getItem("User")));
  //   if (token != undefined || token != null) return true;
  //   else return false;
  // }

  static getAccessToken() {
    let token = localStorage.getItem("User");
    //alert('token:',token)
    if (token != null ) { 
      return token.AccessToken;
    } else {
      //console.log("AccessToken Null:");
      return null;
    }
  }

}
export default LocalStorageHelper;
