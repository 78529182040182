import React, { Component } from 'react';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
//import Handsontable from 'handsontable-pro';

class SearchLead extends Component {
    constructor(props) {
        super(props);
        // register Handsontable's modules
        registerAllModules();
    }

    componentDidMount() {
        window.scroll(0, 0);
    }
    
    render() {
        return (
            <>
                <HotTable
                    data={[
                        ['', 'Tesla', 'Volvo', 'Toyota', 'Ford'],
                        ['2019', 10, 11, 12, 13],
                        ['2020', 20, 11, 14, 13],
                        ['2021', 30, 15, 12, 13]
                    ]}
                    rowHeaders={true}
                    colHeaders={true}
                    height="auto"
                    width="100%"
                    licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                />
            </>
        );
    }
}

export default SearchLead;