import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
    constructor() {
        super();
        this.state = {
            showMasterSubMenu: false,
            showTranSubMenu: false,
            showReportSubMenu: false,
            showUserSubMenu: false
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
    }
    showMasterSubMenu = (e) => {
        e.preventDefault();
        let masterSubMenu = this.state.showMasterSubMenu;
        this.setState({ showMasterSubMenu: !masterSubMenu })
    }

    showTranSubMenu = (e) => {
        e.preventDefault();
        let tranSubMenu = this.state.showTranSubMenu;
        this.setState({ showTranSubMenu: !tranSubMenu })
    }

    showReportSubMenu = (e) => {
        e.preventDefault();
        let rptSubMenu = this.state.showReportSubMenu;
        this.setState({ showReportSubMenu: !rptSubMenu })
    }

    showUserSubMenu = (e) => {
        e.preventDefault();
        let userSubMenu = this.state.showUserSubMenu;
        this.setState({ showUserSubMenu: !userSubMenu })
    }


    render() {
        return (
            <>
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to={"/"}>
                        <div className="sidebar-brand-icon rotate-n-15">
                            <i className="fas fa-laugh-wink"></i>
                        </div>
                        <div className="sidebar-brand-text mx-3">AV Admin </div>
                    </Link>

                    <hr className="sidebar-divider my-0" />

                    <li className="nav-item active">
                        <Link className="nav-link" to={"/"}>
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>

                    <hr className="sidebar-divider" />

                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            to={"/"}
                            onClick={this.showMasterSubMenu}
                        >
                            <i className="fas fa-fw fa-cog"></i>
                            <span>Master</span>
                        </Link>
                        <div id="collapseTwo"
                            className={(this.state.showMasterSubMenu === true) ? "collapse show" : "collapse"}>
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to={"/service-category"}>Service Category</Link>
                                <Link className="collapse-item" to={"/services"}>Services</Link>                                                             
                            </div>
                        </div>
                    </li>

                    <li className="nav-item">
                        <Link
                            className="nav-link collapsed"
                            to={"/"}
                            onClick={this.showTranSubMenu}
                        >
                            <i className="fas fa-fw fa-wrench"></i>
                            <span>Transaction</span>
                        </Link>
                        <div id="collapseTransactions"
                            className={(this.state.showTranSubMenu === true) ? "collapse show" : "collapse"}
                            aria-labelledby="headingUtilities"
                            data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to={"/providers"}>Providers</Link>   
                                <Link className="collapse-item" to={"/"}>Track Bookings</Link>                                 
                            </div>
                        </div>
                    </li>

                    {/* <li className="nav-item">
                        <Link
                            className="nav-link collapsed"
                            to={"/"}
                            onClick={this.showReportSubMenu}
                        >
                            <i className="fas fa-fw fa-wrench"></i>
                            <span>Reports</span>
                        </Link>
                        <div id="collapseReports"
                            className={(this.state.showReportSubMenu === true) ? "collapse show" : "collapse"}
                            aria-labelledby="headingUtilities"
                            data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                               <Link className="collapse-item" to={"/"}>Report-1</Link>
                                <Link className="collapse-item" to={"/"}>Report-2</Link>
                                <Link className="collapse-item" to={"/"}>Report-3</Link>
                                <div className="collapse-divider"></div>
                                <Link className="collapse-item" to={"/"}>404 Page</Link>
                                <Link className="collapse-item" to={"/"}>Blank Page</Link>  
                            </div>
                        </div>
                    </li> */}

                    <hr className="sidebar-divider" />


                    {/* <li className="nav-item">
                        <Link
                            className="nav-link collapsed"
                            to={"/"}
                            onClick={this.showUserSubMenu}
                        >
                            <i className="fas fa-fw fa-folder"></i>
                            <span>Manage Access</span>
                        </Link>
                        <div id="collapsePages"
                            className={(this.state.showUserSubMenu === true) ? "collapse show" : "collapse"}
                            aria-labelledby="headingPages"
                            data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to={"/login"}>Login</Link>
                                <Link className="collapse-item" to={"/register"}>Register</Link>
                                <Link className="collapse-item" to={"/forgotpwd"}>Forgot Password</Link>
                            </div>
                        </div>
                    </li> */}


                    {/* <hr className="sidebar-divider d-none d-md-block" /> */}


                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>


                </ul>
            </>
        );
    }
}

export default Sidebar;