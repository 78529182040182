import React from "react";

const ServiceUnavailable = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="text-muted font-weight-lighter mt-4 align-items-center">
        <h4>Service Not Available</h4>
      </div>
      <div>
        <img
          src={process.env.PUBLIC_URL + "/Images/cloud_offline_128.png"}
          alt="Offline"
          width={64}
          height={64}
        />
      </div>
    </div>
  );
};


export default ServiceUnavailable;
