import React, { useState, useEffect } from "react";
import profileImage from "../../assets/images/undraw_profile.svg";
import profileImage1 from "../../assets/images/undraw_profile_1.svg";
import profileImage2 from "../../assets/images/undraw_profile_2.svg";
import profileImage3 from "../../assets/images/undraw_profile_3.svg";
import { Link } from "react-router-dom";
import LocalStorageHelper from "../../LocalStorageHelper/LocalStorageHelper";
import AdminLogin from "./AdminLogin";
import useUser from "./useUser";

function Topbar({ onLogout }) {
  const { user } = useUser();
 

  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuClick = (menu, event) => {
    event.preventDefault();
    setActiveMenu(activeMenu === menu ? null : menu);   
  };

  const handleClickOutside = (event) => {
    if (activeMenu && !event.target.closest(".menu-item")) {
      setActiveMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [activeMenu]);

  const handleLogoutFromHere = () => {
    LocalStorageHelper.removeUser();
    localStorage.removeItem("isAuthenticated");
    onLogout();
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>

        {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-light border-0 small"
              placeholder="Search for..."
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button className="btn btn-primary" type="button">
                <i className="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form> */}

        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item dropdown no-arrow d-sm-none">
            <Link
              className="nav-link dropdown-toggle"
              to={"/"}
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-search fa-fw"></i>
            </Link>

            <div
              className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li> */}

          <li className="nav-item dropdown no-arrow mx-1">
            <Link
              className="nav-link dropdown-toggle"
              to={"/"}
              id="alertsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={(event) =>
                handleMenuClick("showNotiAlertSubMenu", event)
              }
            >
              <i className="fas fa-bell fa-fw"></i>
              {/* <span className="badge badge-danger badge-counter">0</span> */}
            </Link>

            {activeMenu === "showNotiAlertSubMenu" && (
              <div
                className={
                  "menu-item dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in show"
                }
                aria-labelledby="alertsDropdown"
              >
                <h6 className="dropdown-header">Alerts Center</h6>
                {/* todo */}
                <div className="mr-3">
                    <div className="text-center">
                      No alerts
                    </div>
                  </div>
                {/* <Link
                  className="dropdown-item d-flex align-items-center"
                  to={"/"}
                >
                  <div className="mr-3">
                    <div className="icon-circle bg-primary">
                      <i className="fas fa-file-alt text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 12, 2019</div>
                    <span className="font-weight-bold">
                      Link new monthly report is ready to download!
                    </span>
                  </div>
                </Link> */}
                {/* <Link
                  className="dropdown-item d-flex align-items-center"
                  to={"/"}
                >
                  <div className="mr-3">
                    <div className="icon-circle bg-success">
                      <i className="fas fa-donate text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 7, 2019</div>
                    &#8377;290.29 has been deposited into your account!
                  </div>
                </Link> */}
                {/* <Link
                  className="dropdown-item d-flex align-items-center"
                  to={"/"}
                >
                  <div className="mr-3">
                    <div className="icon-circle bg-warning">
                      <i className="fas fa-exclamation-triangle text-white"></i>
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">December 2, 2019</div>
                    Spending Alert: We've noticed unusually high spending for
                    your account.
                  </div>
                </Link> */}
                {/* <Link
                  className="dropdown-item text-center small text-gray-500"
                  to={"/"}
                >
                  Show All Alerts
                </Link> */}
              </div>
            )}
          </li>

          <li className="nav-item dropdown no-arrow mx-1">
            <Link
              className="nav-link dropdown-toggle"
              to={"/"}
              id="messagesDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={(event) => handleMenuClick("showMessageSubMenu", event)}
            >
              <i className="fas fa-envelope fa-fw"></i>
              {/* <span className="badge badge-danger badge-counter">0</span> */}
            </Link>
            {activeMenu === "showMessageSubMenu" && (
              <div
                className={
                  "menu-item dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in show"
                }
                aria-labelledby="messagesDropdown"
              >
                <h6 className="dropdown-header">Message Center</h6>
                <div className="mr-3">
                    <div className="text-center">
                      No messages
                    </div>
                  </div>
                {/* <Link
                  className="dropdown-item d-flex align-items-center"
                  to={"/"}
                >
                  <div className="dropdown-list-image mr-3">
                    <img
                      className="rounded-circle"
                      src={profileImage1}
                      alt="..."
                    />
                    <div className="status-indicator bg-success"></div>
                  </div>
                  <div className="font-weight-bold">
                    <div className="text-truncate">
                      Hi there! I am wondering if you can help me with Link
                      problem I've been having.
                    </div>
                    <div className="small text-gray-500">
                      Emily Fowler · 58m
                    </div>
                  </div>
                </Link> */}
                {/* <Link
                  className="dropdown-item d-flex align-items-center"
                  to={"/"}
                >
                  <div className="dropdown-list-image mr-3">
                    <img
                      className="rounded-circle"
                      src={profileImage2}
                      alt="..."
                    />
                    <div className="status-indicator"></div>
                  </div>
                  <div>
                    <div className="text-truncate">
                      I have the photos that you ordered last month, how would
                      you like them sent to you?
                    </div>
                    <div className="small text-gray-500">Jae Chun · 1d</div>
                  </div>
                </Link> */}
                {/* <Link
                  className="dropdown-item d-flex align-items-center"
                  to={"/"}
                >
                  <div className="dropdown-list-image mr-3">
                    <img
                      className="rounded-circle"
                      src={profileImage3}
                      alt="..."
                    />
                    <div className="status-indicator bg-warning"></div>
                  </div>
                  <div>
                    <div className="text-truncate">
                      Last month's report looks great, I am very happy with the
                      progress so far, keep up the good work!
                    </div>
                    <div className="small text-gray-500">
                      Morgan Alvarez · 2d
                    </div>
                  </div>
                </Link> */}
                {/* <Link
                  className="dropdown-item d-flex align-items-center"
                  to={"/"}
                >
                  <div className="dropdown-list-image mr-3">
                    <img
                      className="rounded-circle"
                      src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                      alt="..."
                    />
                    <div className="status-indicator bg-success"></div>
                  </div>
                  <div>
                    <div className="text-truncate">
                      Am I Link good boy? The reason I ask is because someone
                      told me that people say this to all dogs, even if they
                      aren't good...
                    </div>
                    <div className="small text-gray-500">
                      Chicken the Dog · 2w
                    </div>
                  </div>
                </Link> */}
                {/* <Link
                  className="dropdown-item text-center small text-gray-500"
                  to={"/"}
                >
                  Read More Messages
                </Link> */}
              </div>
            )}
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>

          <li className="nav-item dropdown no-arrow">
            <Link
              className="nav-link dropdown-toggle"
              to={"/"}
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={(event) => handleMenuClick("showProfileSubMenu", event)}             
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {user && user.UserName}
              </span>
              <img
                className="img-profile rounded-circle"
                src={profileImage}
                alt="..."
              />
            </Link>

            {activeMenu === "showProfileSubMenu" &&
              (user ? (
                <>
                  <div
                    className={
                      "menu-item dropdown-menu dropdown-menu-right shadow animated--grow-in show"
                    }
                    aria-labelledby="userDropdown"
                  >
                    {/* <Link className="dropdown-item" to={"/"}>
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </Link>
                <Link className="dropdown-item" to={"/"}>
                  <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                  Settings
                </Link>
                <Link className="dropdown-item" to={"/"}>
                  <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                  Activity Log
                </Link>
                <div className="dropdown-divider"></div> */}
                    <Link
                      className="dropdown-item"
                      to={"/"}
                      data-toggle="modal"
                      data-target="#logoutModal"
                      onClick={handleLogoutFromHere}
                    >
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </Link>
                  </div>
                </>
              ) : (
                <><Link
                className="dropdown-item"
                to={"/login"}
                data-toggle="modal"
                data-target="#logoutModal"
                onClick={handleLogoutFromHere}
              >                 
                Login
              </Link></>
              ))}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Topbar;
