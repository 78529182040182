import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
 

const SingleSelectDropdown = ({
  options,
  fieldName,
  optionKey,
  getSelectedValue,
  defaultRecord,
}) => {
  const animatedComponents = makeAnimated();
  const [selectedValue, setSelectedValue] = useState(defaultRecord || "");
  const [selectedText, setSelectedText] = useState(
    (options &&
      options.find((option) => option.value === defaultRecord)?.label) ||
      ""
  );
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    getSelectedValue(selectedValue, selectedText, fieldName, optionKey);
  }, [selectedValue, selectedText]);

  const handleDropdownSelect = (eventKey, event) => {
    // `eventKey` is the value of the selected item
    // `event` is the original event, if needed

    // Set the selected text in the state
    setSelectedValue(eventKey.value);

    // Find the corresponding text for the selected value
    const selectedOption = options.find(
      (option) => option.value === eventKey.value
    );

    if (selectedOption) {
      setSelectedText(selectedOption.label);
    }
  };

  return (
    <Select
      closeMenuOnSelect={true}
      isSearchable={true}
      components={animatedComponents}
      options={options.map((option) => ({
        value: option.value ?? option,
        label: option.label ?? option,
      }))}
      isMulti={false}
      onChange={handleDropdownSelect}
      defaultValue={options.filter(function (option) {
        return option.value === selectedValue;
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.hasValue ? "#D7D7E4" : "red",
          minHeight: 32,
          height:22,         
          fontSize:14, 
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          paddingTop: 0,
          paddingBottom: 0,
        }),
      }}
    />
  );
};

export default SingleSelectDropdown;
