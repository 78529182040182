import React, { Component, Fragment, useEffect, useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import URLPage from "../pages/Master/URLPage";
import TravelCircuitPage from "../pages/Master/TravelCircuitPage";
import SpecialistPage from "../pages/Master/SpecialistPage";
import SearchLeadPage from "../pages/Transaction/SearchLeadPage";
import LoginPage from "../pages/Access/LoginPage";
import RegisterPage from "../pages/Access/RegisterPage";
import ForgotPasswordPage from "../pages/Access/ForgotPasswordPage";
import ManageServiceCategoryPage from "../pages/Master/ManageServiceCategoryPage";
import ServicesPage from "../pages/Master/ServicesPage";
import ProvidersPage from "../pages/Master/ProvidersPage";
import AdminLogin from "../components/common/AdminLogin";
import useUser from "../components/common/useUser";
import PrivateRoute from "../components/common/PrivateRoute";

function AppRoute() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { setUser, token } = useUser();

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  useEffect(() => {
    // Check if the user is authenticated in localStorage
    const storedAuth = localStorage.getItem("isAuthenticated");
    if (storedAuth) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Fragment>
      <Switch>      
          <Route path="/login" exact>
            {isAuthenticated ? (
              <Redirect to="/home" />
            ) : (
              <AdminLogin onLogin={handleLogin} setUser={setUser} />
            )}
          </Route>

          <PrivateRoute
            exact
            path="/home"
            component={() => <HomePage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            exact
            path="/"
            component={() => <HomePage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/about"
            component={() => <AboutPage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/url"
            component={() => <URLPage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/tc"
            component={() => <TravelCircuitPage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/spl"
            component={() => <SpecialistPage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/sl"
            component={() => <SearchLeadPage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/service-category"
            component={() => (
              <ManageServiceCategoryPage onLogout={handleLogout} />
            )}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/services"
            component={() => <ServicesPage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/providers"
            component={() => <ProvidersPage onLogout={handleLogout} />}
            isAuthenticated={isAuthenticated}
          />         
      </Switch>
    </Fragment>
  );
}

export default AppRoute;
