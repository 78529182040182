import React, { Component } from 'react';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class URL extends Component {
    constructor(props) {
        super(props);
        // register Handsontable's modules
        registerAllModules();
        this.state={
            URLData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.getAllURLData();
    }

    getAllURLData = () => {
        axios.get(ApiURL.GetAllURL).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                var dataResult = respData.DataResult;
                this.setState({ URLData: dataResult })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }
        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    render() {
        return (
            <>
                <HotTable
                    data={this.state.URLData}
                    rowHeaders={true}
                    colHeaders={true}
                    height="auto"
                    width="100%"
                    licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                />
            </>
        );
    }
}

export default URL;