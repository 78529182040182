import React, { useState } from 'react';
import { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import '../../assets/css/CustomDropdown.css'


const CustomDropdown = ({ options, title, fieldName, optionKey, getSelectedValue, defaultRecord }) => {
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedText, setSelectedText] = useState(defaultRecord || '');
    const [searchText, setSearchText] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);


    const handleDropdownSelect = (eventKey, event) => {
        // `eventKey` is the value of the selected item
        // `event` is the original event, if needed

        // Set the selected text in the state    
        setSelectedValue(eventKey);

        // Find the corresponding text for the selected value
        const selectedOption = options.find((option) => option.value === eventKey);
        if (selectedOption) {
            setSelectedText(selectedOption.text || selectedOption.label);
        }
    }

    const handleSearchChange = (e) => {
        const text = e.target.value;
        setSearchText(text);

        // Filter the options based on the entered text
        const filtered = options.filter((option) =>
            option.text?.toLowerCase().includes(text.toLowerCase()) || option.label?.toLowerCase().includes(text.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    useEffect(() => {
        getSelectedValue(selectedValue, selectedText, fieldName, optionKey)

    }, [selectedValue, selectedText])

    return (
        <Dropdown id={fieldName} title={title} onSelect={handleDropdownSelect} className="ddr" >
            <Dropdown.Toggle id="dropdown-basic" className='w-100 mt-2' variant='transparent'>
                {selectedText === '' ? title : selectedText}
            </Dropdown.Toggle>
            <Dropdown.Menu className='w-100'>
                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        name={'Search_' + fieldName}
                        onChange={handleSearchChange}
                        className='form-control p-2'
                        maxLength={20}
                    />
                </div>
                {filteredOptions.map((option) => (
                    <Dropdown.Item key={fieldName + option.value} eventKey={option.value}>
                        {option.text || option.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>

    )
}

export default CustomDropdown


