import React from "react";
import "../../assets/css/LoadingStyle.css";

const Loading = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="text-muted font-weight-lighter mt-4 align-items-center">
        <h4>Loading...</h4>
      </div>
      <div>
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
