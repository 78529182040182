import React from 'react'
import PropTypes from 'prop-types';

const ConfirmationModal = ({ deleteConfirmation, closeDeleteConfirmation, handleDelete }) => {
    return (
        deleteConfirmation && (
            <div className="delete-confirmation-modal">
                <p>Are you sure you want to delete?</p>
                <button onClick={handleDelete} className='yes btn btn-sm'>Yes</button>
                <button onClick={closeDeleteConfirmation} className='no btn btn-sm'>No</button>
            </div>
        )    
    );
};

ConfirmationModal.propTypes = {   
    deleteConfirmation: PropTypes.bool.isRequired,
    closeDeleteConfirmation: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
    deleteConfirmation: false     
};

export default ConfirmationModal