import React from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const MultiSelectDropdown = ({ options, fieldName, fieldId, getSelectedItems }) => {
    const animatedComponents = makeAnimated(); 

    const handleItemSelection = (items) => {      
        getSelectedItems(fieldName, fieldId, items);
    }

    return (
        <Select
            closeMenuOnSelect={false}
            isSearchable={true}
            components={animatedComponents}
            //defaultValue={[options[1]]}           
            options={options.map((option) => ({
                value: option.value ?? option,
                label: option.label ?? option,
            }))}
            isMulti
            onChange={items => handleItemSelection(items)}
        />
    )
}

export default MultiSelectDropdown