import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Topbar from "../../components/common/Topbar";
import PageTitle from "../../components/home/PageTitle";
import Footer from "../../components/common/Footer";
import GenericTable from "../../components/common/GenericTable";
import DynamicForm from "../../components/common/DynamicForm";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { GetAllData, GetDropdownData } from "../../api/ApiDataServiceCategory";
import ServiceUnavailable from "../../components/common/ServiceUnavailable";
import Loading from "../../components/common/Loading";
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

let initialDataX = [
  {
    ServiceId: 1,
    ServiceMasterId: 10,
    ServiceMasterName: "Driving Instructor",
    ServiceName: "Service 1",
    ServiceDesc: "Service 1 Description",
    ItemImage: "ItemImage",
    Keywords: "Keywords",
    Permalink: "Permalink",
    IsHomeVisit: "Yes",
    IsActive: "Yes",
    UpdatedDate: "2023-09-03",
  },
  {
    ServiceId: 2,
    ServiceMasterId: 9,
    ServiceMasterName: "Man with Van",
    ServiceName: "Service 2",
    ServiceDesc: "Service 2 Description",
    ItemImage: "ItemImage",
    Keywords: "Keywords",
    Permalink: "Permalink",
    IsHomeVisit: "No",
    IsActive: "No",
    UpdatedDate: "2023-09-02",
  },
  {
    ServiceId: 3,
    ServiceMasterId: 8,
    ServiceMasterName: "Domestic Help",
    ServiceName: "Service 3",
    ServiceDesc: "Service 3 Description",
    ItemImage: "ItemImage",
    Keywords: "Keywords",
    Permalink: "Permalink",
    IsHomeVisit: "No",
    IsActive: "No",
    UpdatedDate: "2023-09-02",
  },
  {
    ServiceId: 4,
    ServiceMasterId: 7,
    ServiceMasterName: "Domestic Cleaning",
    ServiceName: "Service 4",
    ServiceDesc: "Service 4 Description",
    ItemImage: "ItemImage",
    Keywords: "Keywords",
    Permalink: "Permalink",
    IsHomeVisit: "Yes",
    IsActive: "No",
    UpdatedDate: "2023-09-01",
  },
  {
    ServiceId: 5,
    ServiceMasterId: 6,
    ServiceMasterName: "Painting Instructor",
    ServiceName: "Service 6",
    ServiceDesc: "Service 6 Description",
    ItemImage: "ItemImage",
    Keywords: "Keywords",
    Permalink: "Permalink",
    IsHomeVisit: "Yes",
    IsActive: "No",
    UpdatedDate: "2023-08-01",
  },
];

// Dropdown options
let serviceMasterOptionsStatic = [
  { value: 10, label: "Driving Instructor" },
  { value: 9, label: "Man with Van" },
  { value: 8, label: "Domestic Help" },
  { value: 7, label: "Domestic Cleaning" },
  { value: 6, label: "Painting Instructor" },
];

const ServicesPage = ({ onLogout }) => {
  const [loading, setLoading] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [filterParameters, setFilterParameters] = useState(null);
  const [serviceMasterOptions, setServiceMasterOptions] = useState([]);
  const [homeVisitOptions, setHomeVisitOptions] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);
  const [unitOfSpan, setUnitOfSpan] = useState([]);

  const addItemApiUrl = ApiURL.CreateServiceApiUrl;
  const editItemApiUrl = ApiURL.EditServiceApiUrl;
  const deleteItemApiUrl = ApiURL.DeleteServiceApiUrl;
  const getAllItemApiUrl = ApiURL.GetAllServicesApiUrl;

  // Table columns
  const columns = [
    { key: "ServiceId", alias: "#", isPrimaryKey: true, isVisible: false },
    { key: "ServiceMasterId", alias: "Category Id", isVisible: false },
    { key: "ItemImage", alias: "Image", type: "image-hlink", height:"54px", width:"54px", colWidth: '30px'},
    { key: "ServiceMasterName", alias: "Category", colWidth: '120px'},
    { key: "ServiceName", alias: "Name", colWidth: '300px' },
    { key: "ServiceDesc", alias: "Description", colWidth: '70px', isVisible: false },
    { key: "Keywords", alias: "Keywords", colWidth: '70px', isVisible: false },
    { key: "Permalink", alias: "Permalink", isVisible: false },
    { key: "IsHomeVisit", alias: "Home Visit?", isVisible: true },
    { key: "IsActive", alias: "Active?", isVisible: false },
    { key: "Span", alias: "Span", isVisible: true },
    { key: "UnitOfSpan", alias: "Unit of Span", isVisible: false },
    // { key: "ActiveParent", alias: "Is Category Active", isParentActive: (value) => value? 'A':'N', render: (value) => value ? "Yes" : "No",},
    
    // { key: "UpdatedDate", alias: "Updated On" },
  ];

  // Ignore columns from search text
  const ignoredColumns = ["ServiceId", "ItemImage"];

  // Blank object for add item
  const addingObject = {
    ServiceId: "", //PK
    ServiceMasterId: "",
    ServiceMasterName: "",
    ItemImage: "",
    ServiceName: "",
    ServiceDesc: "",
    Keywords: "",
    Permalink: "",
    IsHomeVisit: "",
    IsActive: "",
    Span: "",
    UnitOfSpan: "",
  };

  // Data model for add/edit item
  const formDataModel = [
    {
      name: "ServiceId",
      label: "Id",
      type: "text",
      required: false,
      isPrimaryKey: true,
      isReadonly: true,
      isHidden: true,
      showLabel: true,
    },
    //{ name: 'ServiceMasterId', label: 'Category Id', type: 'text', required: false, isReadonly: true, isHidden: false, showLabel: true },
    {
      //name: "ServiceMasterName",
      name: "ServiceMasterId",
      label: "Category",
      type: "single-select",
      title: "Select Category",
      //optionKey: "ServiceMasterId",
      optionKey: "ServiceMasterName",
      options: serviceMasterOptions,
      required: false,
      showLabel: true,
    },
    {
      name: "ItemImage",
      label: "Image",
      type: "image-hlink",
      required: true,
      isReadonly: true,
      isHidden: false,
      showLabel: true,      
    },
    {
      name: "ServiceName",
      label: "Name",
      type: "text",
      required: true,
      isReadonly: false,
      isHidden: false,
      showLabel: true,
    },
    {
      name: "ServiceDesc",
      label: "Description",
      type: "textarea",
      required: true,
      isReadonly: false,
      isHidden: false,
      showLabel: true,
    },
    {
      name: "Keywords",
      label: "Keywords",
      type: "text",
      required: false,
      isReadonly: false,
      isHidden: false,
      showLabel: true,
    },
    // {
    //   name: "Permalink",
    //   label: "Permalink",
    //   type: "text",
    //   required: false,
    //   isReadonly: false,
    //   isHidden: false,
    //   showLabel: true,
    // },
    {
      name: "IsHomeVisit",
      label: "Home Visit?",
      type: "single-select",
      required: true,
      isReadonly: false,
      isHidden: false,
      showLabel: true,
      placeholder: "Yes or No",
      title: "Select Home Visit",
      optionKey: "IsHomeVisit",
      options: homeVisitOptions,
    },
    // {
    //   name: "IsActive",
    //   label: "Active?",
    //   type: "single-select",
    //   required: true,
    //   isReadonly: false,
    //   isHidden: false,
    //   showLabel: true,
    //   placeholder: "Yes or No",
    //   title: "Select Active",
    //   optionKey: "IsActive",
    //   options: activeOptions,
    // },
    {
      name: "Span",
      label: "Span",
      type: "number",
      required: true,
      isReadonly: false,
      isHidden: false,
      showLabel: true,
      placeholder: "0",
      title: "Span of service",
    },
    // {
    //   name: "UnitOfSpan",
    //   label: "Unit of Span",
    //   type: "single-select",
    //   title: "Select unit of span",
    //   optionKey: "UnitOfSpan",
    //   options: unitOfSpan,
    //   required: true,
    //   showLabel: true,
    // },
    // {
    //   name: "UpdatedDate",
    //   label: "Updated On",
    //   type: "datetime",
    //   required: true,
    //   showLabel: true,
    // },
    // {
    //   name: 'skills',
    //   label: 'Skills',
    //   fieldId: "skillsId",
    //   type: 'multi-select',
    //   options: [
    //     { value: '1', label: 'JavaScript 1' },
    //     { value: '2', label: 'React 2' },
    //     { value: '3', label: 'Node.js 3' },
    //     { value: '4', label: 'HTML 4' },
    //     { value: '5', label: 'CSS 5' }
    //   ],
    //   required: false,
    // },
    // {
    //   name: 'skills',
    //   label: 'Skills',
    //   type: 'single-select',
    //   options: options,
    //   required: true,
    // },
    // { name: 'ServiceMasterName', label: 'Category Id', type: 'select', options: ['', 'Male', 'Female', 'Other'], required: true, showLabel: true  },
    //  { name: 'subscribe', label: 'Subscribe', type: 'checkbox', required: false },
  ];

  const getAllDataForDropdown = () => {
    //  let _ddlItems1 = [...serviceMasterOptionsStatic];
    //  setServiceMasterOptions([..._ddlItems1]);    
    // console.log('_ddlItems1', _ddlItems1)

    GetDropdownData(ApiURL.GetAllServiceCategories)
      .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
          //console.log("Dropdown Data:", json.DataResult);
          let _ddlItems = [];
          json.DataResult.map((_data, index) => {
            _ddlItems.push({
              value: _data.ServiceMasterId,
              label: _data.ServiceMasterName,
            });
          });
      

          setServiceMasterOptions([..._ddlItems]);
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        setServiceError(true);
      })
      .finally(() => {
        //setLoading(false);
      });
  };

  //--------------------
  //+ "?pageSize=10&pageNumber=1&searchText="
  const getAllServicesData = () => {
    GetAllData(getAllItemApiUrl)
      .then((json) => {
        setServiceError(false);
        if (json.StatusCode === 200) {
          console.log('Records:',json.DataResult);
          setInitialData(json.DataResult.Records);
          setFilterParameters({
            pageSize: json.DataResult.FilterParameters.PageSize,
            pageNumber: json.DataResult.FilterParameters.PageNumber,
            searchText: json.DataResult.FilterParameters.SearchText,
            totalRecords: json.DataResult.FilterParameters.TotalRecords,
          });
        } else if (json.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        } else {
          cogoToast.error("Request Failed! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        setServiceError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //--------------------

  useEffect(() => {
    setLoading(true);
    getAllServicesData();
    getAllDataForDropdown();

    let spanUnits = [
      { value: "Hrs", label: "Hrs" },
      { value: "Sqft", label: "Sqft" },
      { value: "Sqmt", label: "Sqmt" },
      { value: "Bhk", label: "Bhk" },
      { value: "Others", label: "Others" },
    ];

    let yesNoOptions = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];
    setHomeVisitOptions([...yesNoOptions]);
    setActiveOptions([...yesNoOptions]);
    setUnitOfSpan([...spanUnits]);
  }, [serviceError]);

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar onLogout={onLogout} />
            <div className="container-fluid">
              <PageTitle Title="" />
              <div className="text-dark">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="text-info font-weight-lighter">Services</h4>
                </div>

                {/* List of items with Add/Edit/Delete/Sorting/Paging/Searching */}
                {loading ? (
                  <Loading />
                ) : serviceError ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    {initialData && (
                      <GenericTable
                        data={initialData}
                        columns={columns}
                        ignoredColumns={ignoredColumns}
                        enablePaging={true}
                        enableSorting={true}
                        enableSearching={true}
                        enableDeleting={true}
                        addingObject={addingObject}
                        addTitle="Add Service"
                        editTitle="Edit Service"
                        addItemApiUrl={addItemApiUrl}
                        editItemApiUrl={editItemApiUrl}
                        deleteItemApiUrl={deleteItemApiUrl}
                        getAllItemApiUrl={getAllItemApiUrl}
                        AddItemComp={DynamicForm}
                        EditItemComp={DynamicForm}
                        formDataModel={formDataModel}
                        noOfColumnsLayout={2}
                        pageSizeOptions={[10, 50, 100]}
                        filterParameters={filterParameters}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
