// SearchComponent.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../assets/css/SearchComponent.css";

const SearchComponent = ({ onSearchChange, onClick }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (e) => {
    let text = e.target.value;
    setSearchText(text);    
  };

  const handleClearSearch = () => {
    setSearchText("");
    //onSearchChange("");
    onClick("");    
  };

  const handleClickSearch = () =>{
    onClick(searchText);
  }

  return (
    <div className="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100">
      <div className="input-group my-2">
        <input
          className="form-control py-2 border-right-0 form-control-sm"
          type="text"
          id="example-search-input"
          value={searchText}
          onChange={handleSearchChange}          
          placeholder="Search..."
        />

        {searchText && (
          <div className="input-group-append">
            <div
              className="input-group-text bg-white cursor-pointer"
              id="btnClearSearch"
              onClick={handleClearSearch}
            >
              <i className="fa fa-times"></i>
            </div>
          </div>
        )}
        <span className="input-group-append">
          <div className="input-group-text bg-white" onClick={handleClickSearch}>
            <i className="fa fa-search"></i>
          </div>
        </span>
      </div>
    </div>
  );
};

// SearchComponent.propTypes = {
//     onSearchChange: PropTypes.func.isRequired,
// };

SearchComponent.propTypes = {
  //columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SearchComponent;
