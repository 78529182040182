import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import "../../assets/css/AcceptReject.css";

const AcceptReject = ({
  selectedRecord,
  editedData,
  setEditedData,
  onAcceptReject,
}) => {
  useEffect(() => {
    if (selectedRecord) {
      setEditedData(selectedRecord);
    }
  }, [selectedRecord, setEditedData]);

  const handleAccept = () => {
    let updatedData = { ...selectedRecord };
    updatedData.IsApproved = true;
    setEditedData(updatedData);
    onAcceptReject(updatedData);
  };

  const handleReject = () => {
    let updatedData = { ...selectedRecord };
    updatedData.IsApproved = false;
    setEditedData(updatedData);
    onAcceptReject(updatedData);
  };

  return (
    <div className="d-flex justify-content-center">
      <br />
      <p>Approve or Reject the provider ... </p>

      <div>
        <Button
          variant="primary"
          onClick={handleAccept}
          className="mx-2 btn-sm small-font"         
        >
          Approve
        </Button>
        <Button variant="danger" onClick={handleReject} className="mx-2 btn-sm small-font">
          Reject
        </Button>
      </div>
    </div>
  );
};

export default AcceptReject;
