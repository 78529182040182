class ApiURL {
  ////==========================================================================
  ////Base URL Section
  ////==========================================================================
  ////Local
  // static BaseURL = "https://localhost:7268";
  //static BaseURL = "https://localhost:5073";
  //static BaseURL2 = "http://localhost:5073";
  //static BaseURL3 = "http://localhost:22474"; //local
  static BaseURL = "https://service.myboogy.co.uk"; //prod

  ////Production
  //static BaseURL = "http://103.168.18.29";
  //static BaseURL = "https://ilsheguriservice.slicebeans.com";

  ////==========================================================================
  ////Image URL Section
  ////==========================================================================
  static BaseImageURL = this.BaseURL + "/uploadfiles/images";
  static HomeBannerImageURL = this.BaseImageURL + "/HomeBanner";
  static HomeDestinationImageURL = this.BaseImageURL + "/HomeDestination";
  static PackagesWeOfferImageURL = this.BaseImageURL + "/HomePackageWeOffer";
  static TestimonialClientImageURL = "https://mdbcdn.b-cdn.net/img/Photos/Avatars";
  //static TestimonialClientImageURL = this.BaseImageURL + "/Testimonial";
  static HotPackageImageURL = this.BaseImageURL + "/HotPackages";
  static PackageTopBannerImageURL = this.BaseImageURL + "/Packages/TopBanners";
  static PackageDestinationBannerImageURL = this.BaseImageURL + "/Packages/Destinations";
  static HotelImageURL = this.BaseImageURL + "/Packages/Hotels";

  /*  static HomeServiceImageURL = this.BaseImageURL + "/HomeService";
   static BsetOfferImageURL = this.BaseImageURL + "/BestOffer";
   static PopularServiceImageURL = this.BaseImageURL + "/PopularService";
   static MarketplaceImageURL = this.BaseImageURL + "/Marketplace";
   static OtherServiceImageURL = this.BaseImageURL + "/OtherService";
    */

  ////===========================================================================
  ////API URL Section
  ////===========================================================================
  static SaveCustomerEnquery =  this.BaseURL + "/api/customer/saveCustomerEnquery";
  static GetAllOriginCities = this.BaseURL + "/api/customer/getAllOriginCities";
  static GetAllURL = this.BaseURL + "/api/url/getAllURL";
  //static AdminLogin = this.BaseURL + "/api/account/adminLogin";
 

  //Service Categories
  static GetAllServiceCategories = this.BaseURL + "/api/servicecategory/categories";
  static GetServiceCategoriesSearch = this.BaseURL + "/api/servicecategory/categorysearch";
  static CreateServiceCategory = this.BaseURL + "/api/servicecategory/create";
  static EditServiceCategory = this.BaseURL + "/api/servicecategory/update/{id}";
  static DeleteServiceCategory = this.BaseURL + "/api/servicecategory/delete/{id}";

  //Services
  static GetAllServicesApiUrl = this.BaseURL + "/api/service/allservices";
  static CreateServiceApiUrl = this.BaseURL + "/api/service/create";
  static EditServiceApiUrl = this.BaseURL + "/api/service/update/{id}";
  static DeleteServiceApiUrl = this.BaseURL + "/api/service/delete/{id}";

  //Providers
  static GetAllProviders = this.BaseURL + "/api/provideradmin/providers";
  static ApproveOrRejectProvider = this.BaseURL + "/api/provideradmin/approveOrReject";

  //Admin Login
  static AdminLoginApiUrl = this.BaseURL + "/api/auth/adminLogin";


  
  /* static CustomerRegistration = this.BaseURL + "/api/customer/customerRegistration";
  static OTPVerification = this.BaseURL + "/api/customer/validateOTP";
  static ResendOTP = this.BaseURL + "/api/customer/resendOTP";
  static GetUserProfile = this.BaseURL + "/api/customer/getCustomerProfile";
  static CustomerLogin = this.BaseURL + "/api/customer/customerLogin";
  static GetAllProviderDataList = this.BaseURL + "/api/provider/getAllProviderDataList";
  static ProviderRegistration = this.BaseURL + "/api/provider/providerRegistration"; */

  ////============================================================================
  /* static CategoryImageURL = this.BaseImageURL + "/category/";
  static SubCategoryImageURL = this.BaseImageURL + "/subcategory/";
  static ProductImageURL = this.BaseImageURL + "/product/";
  static ProductOriginalImageURL = this.BaseImageURL + "/product/original/";
  static ProductCartImageURL = this.BaseImageURL + "/product/thumb/cart_img/";
  static ProductImage1URL = this.BaseImageURL + "/product/thumb/img_1/";
  static ProductImage2URL = this.BaseImageURL + "/product/thumb/img_2/";
  static ProductImage3URL = this.BaseImageURL + "/product/thumb/img_3/";
  static ProductImage4URL = this.BaseImageURL + "/product/thumb/img_4/";
  static ProductImage5URL = this.BaseImageURL + "/product/thumb/img_5/";

  static BannerImageURL = this.BaseImageURL + "/banner/";
  static UserImageURL = this.BaseImageURL + "/user/";

  static BrandImageURL = this.BaseImageURL + "/brand/";
  static LoginImageURL = this.BaseImageURL + "/login/";

  static GetLocationDetails = this.BaseURL + "/api/homedata/getAllLocation";

  static CreateOTP = this.BaseURL + "/api/authentication/createOTP";


  static GetHomeSliderData = this.BaseURL + "/api/homedata/getAllHomeSliderData";
  static GetAllCategoryData = this.BaseURL + "/api/homedata/getAllCategoryData";
  static GetAllHomeInfo = this.BaseURL + "/api/homedata/getAllHomeInfo";
  static AddItemToCart = this.BaseURL + "/api/cart/addItemToCart";
  static RemoveCartItem = this.BaseURL + "/api/cart/removeCartItem";
  static GetCartListCount = this.BaseURL + "/api/cart/getCartListCount";
  static GetCartDetails = this.BaseURL + "/api/cart/getCartDetails";
  static UpdateCartLineItem = this.BaseURL + "/api/cart/updateCartLineItem";
  static GetAllProductWeights = this.BaseURL + "/api/product/getAllProductWeights";
  static GetShippingDetails = this.BaseURL + "/api/checkout/getShippingDetails";
  static SaveOrder = this.BaseURL + "/api/order/saveOrder";
  static CancelOrder = this.BaseURL + "/api/order/cancelOrder";
  static OrderHistory = this.BaseURL + "/api/order/orderHistory";

  static ProductDetails(ProductCode) {
    return this.BaseURL + "/api/product/productDtls/" + ProductCode;
  }
  static GetSimilarProducts(ProductCode, Cat1Id) {
    return this.BaseURL + "/api/product/getSimilarProducts/" + ProductCode + "/" + Cat1Id;
  }
  static ProductListBySearch(searchKey) {
    return this.BaseURL + "/api/product/productListBySearch/" + searchKey;
  }
  static ProductListByCategory(Cat1Name) {
    return this.BaseURL + "/api/product/productListByCategory/" + Cat1Name;
  }
  static ProductListBySubcategory(Cat1Name, Cat2Name) {
    return this.BaseURL + "/api/product/productListBySubcategory/" + Cat1Name + "/" + Cat2Name;
  } */
}

export default ApiURL;
